.superman-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    @include flex-row-center-center;
    width: 100%;
    height: 100vh;
    transition: all 1s;
    overflow: hidden;
    background-color: $yellow;
    animation: fadeOut 1s 10s alternate linear forwards;
    img{
        width: 150px;
        max-width: 300px;
        animation: flyAway 15s alternate linear forwards;
        transform: translateX(100%);
    }
    .superman{
        margin-left: -30px;
    }
    .house{
        z-index: 1;
        margin-top: 70px;
        margin-right: -50px;
    }
    .flag{
        position: absolute;
        left: 50%;
        transform: translateX(170%);
        animation: flyHalf 10s linear forwards;
    }
}

@media (min-width: 750px) {
    .superman-container{
        img{
            width: 500px;
        }
        .superman{
            margin-left: -65px;
        }
        .house{
            margin-top: 150px;
            margin-right: -100px;
        }
    }
    
}

@media (min-width: 1200px) {
    .superman-container{
        // img{
        //     // width: 15%;
        // }
        // .superman{
        //     margin-left: -80px;
        // }
        // .house{
        //     margin-top: 150px;
        //     margin-right: -80px;
        // }
    }
}

@keyframes flyAway {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-500%);
    }
}
@keyframes flyHalf {
    0%{
        transform: translateX(170%);
    }
    55%{
        transform: translateX(-50%);
    }
    100%{
        transform: translateX(-50%);
    }
}
@keyframes fadeOut {
    0%{
        opacity: 1;
    }
    90%{
        opacity: 0;
        width: 100%;
        height: 100vh;
    }
    100%{
        opacity: 0;
        width: 0;
        height: 0;
    }
}