html,
body,
#root {
    position: relative;
    width: 100%;
    height: 100% !important;
    background-color: $white;
    scroll-behavior: smooth;
    overflow-x: visible;
    font-family: $font-gensen !important;
    background-color: $yellow;
}
header,
footer {
    text-align: center;
    color: $red;
    width: 100%;
}
a,
a:link,
a:hover,
a:active {
    color: $blue;
    text-decoration: none;
}
