.contact {
    @include flex-col-center-center;
    width: 100%;
    margin: calc(30vw / $str-wid-m) 0;
    letter-spacing: 3px;
    &__icon {
        width: calc(20vw / $str-wid-m);
    }
    &__title {
        color: $gray;
    }
    &__content {
        text-align: center;
        color: $gray;
        line-height: 1.5;
    }
    &__btn {
        position: relative;
        color: $red;
        padding: 15px 30px;
        padding-right: 45px;
        border-radius: 100px;
        border: 1px solid $red;
        margin-top: 20px;
        transition: 0.5s all;
        &::after {
            @include pseudo;
            top: 50%;
            right: 20px;
            width: 20px;
            height: 20px;
            background: url(../../img/icons/bi_arrow-left-short.png) no-repeat center / 100%;
            transform: translateY(-50%);
        }
        &:hover {
            color: $white;
            padding: 15px 30px;
            background-color: $red;
        }
    }
    &__tags {
        margin: calc(10vw / $str-wid-m);
        display: inline-block;
        li {
            display: inline-block;
            font-size: calc(10vw / $str-wid-m);
            border-radius: 10px;
            color: $white;
            background-color: $red;
            padding: 2px 10px;
            margin: calc(2vw / $str-wid-m);
        }
    }
    &__video {
        position: relative;
        width: 100%;
        &::before {
            @include pseudo;
            position: relative;
            padding-top: 9/16 * 100%;
        }
        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
@media (min-width: 750px) {
    .contact {
        margin: 20px 0 40px;
        font-size: 20px;
        &__icon {
            width: 30px;
        }
        &__tags {
            margin: 20px;
            li {
                font-size: 20px;
                border-radius: 30px;
                padding: 2px 10px;
                margin: 10px;
            }
        }
        &__video {
            width: 80%;
        }
    }
}
